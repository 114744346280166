import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import markdown from '../../lib/markdown-parser';
import './EditorialText.scss';
import { Grid, Row, Cell } from './../Grid';

const EditorialText = ({data, isFeaturedItem, lightTheme, makeDarkMode}) => {

	const textAlign = data.alignment == 'Center'
		? 'center'
		: (data.alignment == 'Right' ? 'right' : '');


	var editorialItem = data.content && data.content.split("***");
	console.log(editorialItem, 'item!!>>')

	const editorialTextItem = (obj) => {
		console.log(obj, 'obj!!>>')
		var urlMatcher = obj.match(/\(\/\/.*\)/g);
		if (urlMatcher != null) {
			var fetchUrl = urlMatcher[0].substring(1, urlMatcher[0].length - 1);
			if (urlMatcher[0].match(/\.(jpg|jpeg|png|gif|bmp|webp|JPG|JPEG|PNG|GIF|BMP|WEBP)/g)) {
				var imageAlt = obj.match(/\[.*\]/g);
				return <div className="media-element image-viewer"><img src={fetchUrl} alt={imageAlt[0].substring(1, imageAlt[0].length - 1)}/>
				</div>;
			} else if (urlMatcher[0].match(/\.(mp4|m3u8|MP4|M3U8|HLS|hls)/g)) {
				return <ReactPlayer
					className="media-element video-player"
					url={fetchUrl}
					controls={true}
					width='100%'
					height='100%'
				/>
			}
		}
		else {
			return <div
				className={'c-editorial-text ' + textAlign}
				dangerouslySetInnerHTML={{ __html: markdown(obj) }} />;
		}
	}

	return (
		<Grid
			className={`c-editorial-text__section has-editorial-spacing
			${isFeaturedItem ? 'is-featured-item' : ''}
			${(isFeaturedItem && !lightTheme) || makeDarkMode ? 'dark-theme' : ''}`}
			noVerticalPadding>
			<Row>
				{isFeaturedItem || data.alignment === 'FarLeft' ? null : <Cell xlCols={2} lgCols={2}/>}
				<Cell xlCols={8} lgCols={8} mdCols={6} smCols={2}>

					{
						!!data.heading &&
						<p className="c-editorial-text__section__heading">
							{data.heading}
						</p>
					}
					{
						!!data.introduction &&
						<p className="c-editorial-text__section__intro">
							{data.introduction}
						</p>
					}

					{editorialItem && editorialItem.map((obj, i) => (
						obj && obj !== "\n" && obj !== "" && !/^\s*$/.test(obj) &&
						<div key={i}>
							{editorialTextItem(obj)}
						</div>
					))}
				</Cell>
			</Row>
		</Grid>
	);
};

EditorialText.propTypes = {
	data: PropTypes.object.isRequired,
};

export default EditorialText;
